import * as React from "react";
import {Link} from "gatsby";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import Testimonials from "../../components/testimonials/Testimonials";

const bannerImg = '../../banners/personnel-supply_banner.jpg';

//RENDER
export default function PersonnelSupplyPage() {
    return (
        <Layout pageTitle={'Personnel Supply'}>
            <SplashBanner
                title={"Personnel Supply"}
                content={"Bespoke solutions tailored to each customers requirements, including Contract Workforce, Permanent Employee Hire, Payroll & Visa solutions and Retained Searches."}
                backgroundImg={bannerImg}
            />

            {/*A Bespoke Solution*/}
            <section id="personnelSupplyIntro">
                <div className="container">
                    <div className="split-content">
                        <div className="module text">
                            <h2>A bespoke solution</h2>
                            <p>We offer a variety of bespoke solutions that we tailor to each customers requirements;</p>
                            <ul className="formatted-list">
                                <li><strong>Contract Workforce: </strong>Contractors sourced by us for short, medium & long term placements on a hourly, daily or project fee basis</li>
                                <li><strong>Permanent Employee Hire: </strong>Employees sourced by us Employed by our clients</li>
                                <li><strong>Payroll & Visa solutions: </strong>Contractors sourced by the customer payrolled & insured by us</li>
                                <li><strong>Retained & Silent searches: </strong>Sourced employees without public advertisement in the market</li>
                            </ul>
                            <Link to={'/contact-us'} className="button primary arrow-light">
                                <span>Enquire Today</span>
                            </Link>
                        </div>
                        <div className="module media">
                            <img
                                src={"../../article/personnel-supply_article.jpg"}
                                alt={'A bespoke solution'}/>
                        </div>
                    </div>
                </div>
            </section>

            <Testimonials />

        </Layout>
    )
}
